<template>
  <div class="w-full">
    <div
      class="w-full bg-gray-50 border border-gray-200 mt-8 py-3 px-5 rounded-md flex justify-between items-center"
    >
      <div class="flex flex-row">
        <button
          type="button"
          @click="goTo('tables')"
          :class="
            isCurrentTab('tables')
              ? 'bg-gray-200 text-gray-900 group flex items-center px-3 py-2 text-sm font-semibold rounded-md mr-2'
              : 'text-gray-600 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-semibold rounded-md mr-2'
          "
        >
          Tablas
        </button>
        <button
          type="button"
          @click="goTo('selected')"
          :class="
            isCurrentTab('selected')
              ? 'bg-gray-200 text-gray-900 group flex items-center px-3 py-2 text-sm font-semibold rounded-md mr-2'
              : 'text-gray-600 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-semibold rounded-md mr-2'
          "
        >
          Perfil Seleccionado
        </button>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Admin',
  methods: {
    goTo(tab) {
      let nameArray = this.$route.name.split('/');
      nameArray[nameArray.length - 1] !== tab &&
        this.$router.push({ path: `/admin/${tab}` });
    },
    isCurrentTab(tab) {
      let nameArray = this.$route.name.split('/');
      if (tab === 'selected') {
        return nameArray[nameArray.length - 2] === tab;
      } else {
        return nameArray[nameArray.length - 1] === tab;
      }
    },
  },
};
</script>
